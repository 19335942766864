import React from 'react';
import './ConfirmationPage.css';
import logoImage from './DWM logo.png';

function ConfirmationPage() {
  return (
    <div className="container">
      <img src={logoImage} alt="Company Logo" style={{ width: '300px', display: 'block', margin: '0 auto 10px' }} />
      <h1 className="title">Thank you for opting in!</h1>
      <p className="message">You will receive a call from Scottish Power shortly on: 0121 732 9788</p>
    </div>
  );
}

export default ConfirmationPage;
