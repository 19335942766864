import React, { useState } from 'react';
import './CustomerForm.css'; 
import logoImage from './DWM logo.png';

const CustomerForm = () => {
  const [formData, setFormData] = useState({
    agentName: '',
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phone: '',
    email: '',
    currentSupplierEnergy: '',
    fuelType: '',
    paymentType: '',
    isOver18: '',
    homeownerOrTenant: '',
    agreeScottishEnergyCall: '',
    agreeTomatoEnergyCall: '',
    broadbandRenewalDate: '',
    commentsEnergy: '',
    currentPaymentEnergy: '',
    currentPaymentBroad: '',
    currentSupplierBroadband: '',
    currentSpeed: '',
    agreeTextsCallsBroad: '',
    commentsBroad: '',
    agreeSMS: false, // Added field for SMS consent
    agreeEmail: false // Added field for email consent
  });

  const agents = [
    "Aston McGolgan",
    "Yuri Decaine",
    "Izari Morgan",
    "Reis Williams",
    "Aiden Marfleet",
    "Jan Berri",
    "Fawwaz Vovaju",
    "Simon Spickett",
    "Greg Mcdough",
    "Hidayah Dayah",
    "Diego Lara",
    "John Cochrane",
    "Dilraj Singh",
    "Robbie Turley",
    "Jack Taylor"
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    let updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value // Handle checkbox change
    };

    if (name === 'dateOfBirth') {
      const isOver18 = checkIfBetween18And75(value);
      updatedFormData = {
        ...updatedFormData,
        isOver18: isOver18 ? 'Yes' : 'No'
      };
    }

    setFormData(updatedFormData);
  };

  const checkIfBetween18And75 = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age >= 18 && age <= 75;
  };

  const handleSubmit = (e) => {
    e.preventDefault();  // Prevent default form submission behavior
    console.log('Form Data:', formData);

    // Convert Boolean values to 'Yes'/'No'
    const submitData = {
      ...formData,
      agreeSMS: formData.agreeSMS ? 'Yes' : 'No',
      agreeEmail: formData.agreeEmail ? 'Yes' : 'No'
    };
  
    fetch('https://verificationteam.co.uk/submit.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submitData), // Use submitData here
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(text => {
      // Check if the text is not empty before parsing it
      const data = text ? JSON.parse(text) : {};
      console.log('Success:', data);
      if (data.status === 'success') {
        alert('Form submitted successfully!');
        window.location.reload();  // Reload the page to reset the form
      } else {
        alert('Error submitting form: ' + (data.message || 'Unknown error'));
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error submitting form!');
    });
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <img src={logoImage} alt="Company Logo" style={{ width: '300px', display: 'block', margin: '0 auto 10px' }} />
      <h1>Agent Details</h1>
      <label className="label">Agent Name:</label>
      <select
        name="agentName"
        value={formData.agentName}
        onChange={handleChange}
        className="select-field"
        required
      >
        <option value="" disabled>Select an Agent</option>
        {agents.map(agent => (
          <option key={agent} value={agent}>{agent}</option>
        ))}
      </select>
      
      <h1>Customer Details</h1>
      <label className="label">Title:</label>
      <select name="title" value={formData.title} onChange={handleChange} className="select-field">
        <option value="" disabled>Select</option>
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Ms">Ms</option>
      </select>
      <br />
      <label className="label">First Name:</label>
      <input type="text" placeholder="First Name" required name="firstName" value={formData.firstName} onChange={handleChange} className="input-field" />
      <br />
      <label className="label">Last Name:</label>
      <input type="text" placeholder="Last Name" required name="lastName" value={formData.lastName} onChange={handleChange} className="input-field" />
      <br />
      <label className="label">Date of Birth:</label>
      <input type="date" required name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} className="input-field"/>
      <br />
      <label className="label">Are you between the ages of 18 and 75?</label>
      <select name="isOver18" value={formData.isOver18} onChange={handleChange} className="select-field" disabled>
        <option value="" disabled>Select</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <br />
      <label className="label">Phone:</label>
      <input type="tel" placeholder="Phone" required name="phone" value={formData.phone} onChange={handleChange} className="input-field"/>
      <br />
      <label className="label">Email:</label>
      <input type="email" placeholder="Email" required name="email" value={formData.email} onChange={handleChange} className="input-field"/>
      <br />
      <label className="label">
      We work with a range of gas and electricity partners;  Would you be interested in a call to fix your rate & protect you from future rises?
      </label>
      <div style={{background: '#DCDCDC', color: 'red', borderColor: 'lime', height: '1px',}}> </div>
      
      <label className="label" style={{padding:'18px 0px 0px 0px'}}>Who is your current supplier?</label>
      <select name="currentSupplierEnergy" value={formData.currentSupplierEnergy} onChange={handleChange} className="select-field">
        <option value="" disabled>Select</option>
        <option value="E.ON Next">E.ON Next</option>
        <option value="E.ON">E.ON</option>
        <option value="EDF">EDF</option>
        <option value="British Gas">British Gas</option>
        <option value="Octopus">Octopus</option>
        <option value="OVO">OVO</option>
        <option value="Scottish Power">Scottish Power</option>
        <option value="Good Energy">Good Energy</option>
        <option value="Ecotricity">Ecotricity</option>
        <option value="Utilita">Utilita</option>
        <option value="So Energy">So Energy</option>
        <option value="E Gas & Power">E Gas & Power</option>
        <option value="Outfox the Market">Outfox the Market</option>
        <option value="Rebel Energy">Rebel Energy</option>
        <option value="Fuse">Fuse</option>
        <option value="Jellyfish">Jellyfish</option>
        <option value="Boost">Boost</option>
        <option value="Utility Warehouse">Utility Warehouse</option>
        <option value="Other">Other</option>
      </select>

      <br />
      <label className="label">Dual or Single Fuel?</label>
      <select name="fuelType" value={formData.fuelType} onChange={handleChange} className="select-field">
        <option value="" disabled>Select</option>
        <option value="Dual">Dual</option>
        <option value="Single">Single</option>
      </select>
      <br />
      {formData.fuelType && (
        <div>
          <label className="label">Are you happy to receive a call from Scottish Power, confirming you're the Bill Payer for your property?</label>
          <select name="agreeScottishEnergyCall" value={formData.agreeScottishEnergyCall} onChange={handleChange} className="select-field">
            <option value="" disabled>Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <div className="privacy-link">
            <a href=" https://www.scottishpower.co.uk/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Scottish Power Privacy Policy</a>
          </div>
        </div>
      )}
      <br />
      <label className="label">Payment Type:</label>
      <select name="paymentType" value={formData.paymentType} onChange={handleChange} className="select-field">
        <option value="" disabled>Select</option>
        <option value="Direct debit">Direct debit</option>
        <option value="Prepayment">Prepayment</option>
        <option value="Smart prePayment">Smart prePayment</option>
        <option value="Pay on Receipt of Bill">Pay on Receipt of Bill</option>
      </select>
      <br />
      <label className="label">Are you a homeowner or a tenant?</label>
        <select name="homeownerOrTenant" value={formData.homeownerOrTenant} onChange={handleChange} className="select-field">
          <option value="" disabled>Select</option>
          <option value="Homeowner">Homeowner</option>
          <option value="Tenant">Tenant</option>
        </select>
      <br />
      <label className="label">How much are you currently paying per month?</label>
      <input 
        type="text" 
        placeholder="Enter your monthly payment" 
        required 
        name="currentPaymentEnergy" 
        value={formData.currentPaymentEnergy} 
        onChange={handleChange} 
        className="input-field"
      />
      <br />
      <label className="label">Comments:</label>
      <textarea name="commentsEnergy" value={formData.commentsEnergy} onChange={handleChange} className="input-field"></textarea>
      <br />
      <label className="label">
      I know that fast broadband is just as important as hot water in this day and age. Alongside G&E partners we also work alongside a number of Broadband providers; would you be interested in seeing if your property is eligible for full fibre? 
      </label>
      <div style={{background: '#DCDCDC', color: 'red', borderColor: 'lime', height: '1px',}}> </div>
      <label className="label" style={{padding:'18px 0px 0px 0px'}}>Who is your current supplier?</label>
      <select name="currentSupplierBroadband" value={formData.currentSupplierBroadband} onChange={handleChange} className="select-field">
        <option value="" disabled>Select</option>
        <option value="Fresh Fibre">Fresh Fibre</option>
        <option value="TalkTalk">TalkTalk</option>
        <option value="BT">BT</option>
        <option value="EE">EE</option>
        <option value="Virgin Media">Virgin Media</option>
        <option value="Sky broadband">Sky broadband</option>
        <option value="Shell broadband">Shell broadband</option>
        <option value="Vodafone">Vodafone</option>
        <option value="Utility Warehouse">Utility Warehouse</option>
        <option value="Hyperoptic">Hyperoptic</option>
        <option value="Zen">Zen</option>
        <option value="Community Fibre">Community Fibre</option>
        <option value="Plusnet">Plusnet</option>
        <option value="Other">Other</option>
      </select>
      <label className="label">How much are you currently paying per month?</label>
      <input 
        type="text" 
        placeholder="Enter your monthly payment" 
        // required 
        name="currentPaymentBroad" 
        value={formData.currentPaymentBroad} 
        onChange={handleChange} 
        className="input-field"
      />
      <br />
      <label className="label">What is your current broadband speed?</label>
      <input 
        type="text" 
        placeholder="Enter your broadband speed (e.g., 100 Mbps)" 
        // required 
        name="currentSpeed" 
        value={formData.currentSpeed} 
        onChange={handleChange} 
        className="input-field"
      />
      <br />
      <label className="label">What is your renewal date?</label>
      <input type="date" name="broadbandRenewalDate" value={formData.broadbandRenewalDate} onChange={handleChange} className="input-field"/>
      <br />
      <label className="label">Are you happy to receive a call from DWM's chosen third party partners about your broadband?</label>
      <select name="agreeTextsCallsBroad" value={formData.agreeTextsCallsBroad} onChange={handleChange} className="select-field">
        <option value="" disabled>Select</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <br />
      <label className="label">Comments:</label>
      <textarea name="commentsBroad" value={formData.commentsBroad} onChange={handleChange} className="input-field"></textarea>
      <br />
      <div className="checkbox-container">
        <label className="checkbox">
          I am happy to receive an SMS from DWM.
          <input type="checkbox" name="agreeSMS" checked={formData.agreeSMS} onChange={handleChange} />
          <span className="custom-checkbox"></span>
        </label>
      </div>
      <br />
      <div className="checkbox-container">
        <label className="checkbox">
          I am happy to receive an email from DWM.
          <input type="checkbox" name="agreeEmail" checked={formData.agreeEmail} onChange={handleChange} />
          <span className="custom-checkbox"></span>
        </label>
      </div>
      <br />
      <button type="submit" className="button-submit">Submit</button>
      <div className="privacy-link">
        <a href="https://yourdwm.co.uk/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </div>
    </form>
  );
};

export default CustomerForm;

